@use '../../scss/' as *;


.movie-card {
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 160%;
    border-radius: 20px;
    margin-bottom: 1rem;


    .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform .3s ease, box-shadow .3s ease;
    }

    &::before {
        content: "";
        position: absolute;
        top:0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $black;
        opacity: 0;
        transition: opacity .3s ease;
        border-radius: 20px;
    }

    
        &:hover::before {
            opacity: .5;
        }
        &:hover .btn {
            transform: translate(-50%, -50%)
        }
    


}