@use "../../scss" as *;

.modal {
  @include flex(center, center);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba($color: $black, $alpha: 0.4);
  opacity: 0;
  visibility: hidden;

  &.active {
      width: 100%;
      opacity: 1;
      visibility: visible;
  }

  &__content {
      padding: 2rem;
      background-color: $body-bg;
      width: 50%;
      opacity: 0;
      transform: translateY(-250px);
      transition: transform .6s ease, opacity .6s ease;
      position: relative;

      @include tablet {
          width: 80%;
      }

      &__close {
          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 1.5rem;
          cursor:pointer;

          &:hover {
              color: $main-color;
          }
      }
  }

  &.active &__content {
      opacity: 1;
      transform: translateY(0);

  }
}
